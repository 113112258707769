<template>
  <div class="main-top">
    <div class="header">
      <img src="../../assets/底部信息栏/顶部菜单栏logo.png" style="width: 150px;height: 50px;" />
      <!-- :default-active="activeMenu" -->
      <div>
        <el-menu class="el-menu-demo" mode="horizontal" @select="handleSelect" router active-text-color="#dd5410" text-color="#000">
          <el-menu-item :class="$route.path == '/' ? 'isActive' : ''" index="/">首页</el-menu-item>
          <el-menu-item :class="$route.path == '/Pooro' ? 'isActive' : ''" index="/Pooro">服务方案</el-menu-item>
          <el-menu-item :class="$route.path == '/Commodity' ? 'isActive' : ''" index="/Commodity">产品中心</el-menu-item>
          <!-- <el-submenu index="3">
          <template slot="title">产品中心</template>
          <div class="popUp">
            <div class="flex-lrb" v-for="item in list" :key="item.id">
              <div class="flex-sc title " :class="{ isActive: item.isShow }" @click="clickShow(item.id)">
                {{ item.title }}
              </div>
              <div class="flex-lr classList" v-if="item.isShow">
                <div v-for="(v, n) in item.classArr" @click="skipParticulars(item)" :key="n">
                  {{ v }}
                </div>
              </div>
            </div>

          </div>
        </el-submenu> -->

          <el-menu-item :class="$route.path == '/Presscenter' ? 'isActive' : ''" index="/Presscenter">新闻中心</el-menu-item>
          <el-menu-item :class="$route.path == '/Partner' ? 'isActive' : ''" index="/Partner">合作伙伴</el-menu-item>
          <el-menu-item :class="$route.path == '/AboutUs' ? 'isActive' : ''" index="/AboutUs">关于我们</el-menu-item>
          <el-menu-item :class="$route.path == '/ContactUs' ? 'isActive' : ''" index="/ContactUs">联系我们</el-menu-item>

        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "1",
      list: [
        {
          title: "电脑",
          id: "0",
          Url: "https://p1.lefile.cn/fes/cms/2023/08/11/bx9blpipkbmwaick5epqexaajj7zmw352265.jpg",
          classArr: ["", "华硕", "外星人", "机械师", "联想"],
          isShow: true,
        },
        {
          title: "手机",
          id: "1",
          Url: "https://img.youpin.mi-img.com/ferriswheel/81ba04e6_612b_4463_a33b_3d924a63efcb.jpeg@base@tag=imgScale&F=webp&h=1080&q=90&w=2560",
          classArr: ["", "苹果", "小米", "华为", "魅族"],
          isShow: false,
        },
        {
          title: "手表",
          id: "2",
          Url: "https://www.longines.cn/media/wysiwyg/home/get-more8.18.jpg",
          classArr: ["", "劳力士", "欧米茄", "浪琴", "百达翡丽"],
          isShow: false,
        },
        {
          title: "冰箱",
          id: "3",
          Url: "http://www.ronshen.com.cn/upload/2021/04/13/16182812355184arxzs.jpg",
          classArr: ["", "格力", "西门子", "海信", "海尔"],
          isShow: false,
        },
      ],
    };
  },

  methods: {
    clickShow(v) {
      this.list.map((item) => {
        item.isShow = false;
        this.list[v].isShow = true;
      });
    },
    skipParticulars(i) {
      console.log(i);
      sessionStorage.setItem("obj", JSON.stringify(i));
      if (this.$route.path != "/Particulars") {
        this.$router.push("/Particulars");
      } else {
        this.$router.go(0);
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  computed: {
    // 导航栏高亮
    // activeMenu() {
    //   const route = this.$route
    //   const { meta, path } = route
    //   // 导航栏高亮
    //   if (meta.activeMenu) {
    //     return meta.activeMenu
    //   }
    //   return path
    // },
  },
};
</script>

<style lang="scss" scoped>
.title:hover {
  color: #dd5410;
}

.main-top {
  width: 100%;
  // position: absolute;
  // float: right;
  z-index: 10;
}

.header {
  padding: 0 15%;
  display: flex;
  justify-content: space-between;
}

.popUp {
  width: 50vw;
  height: 20vh;
  background-color: #fff;
  padding: 20px 20px 40px 20px;
}

.title {
  width: 10%;
  border-right: 3px solid #dd5410;
  z-index: 5;
}

.classList {
  width: 70%;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 25px;
}

.classList :hover {
  color: #dd5410;
}

.isActive {
  color: #dd5410;
}
</style>

<style>
/* 导航栏样式 */
.isActive {
  color: #dd5410 !important;
}

.el-menu-item,
.el-menu {
  height: 50px !important;
  line-height: 50px !important;
  border: none !important;
  background: transparent !important;
}

/* 下拉箭头 */
.el-submenu__icon-arrow {
  display: none;
}
</style>