<template>
  <div v-show="isShow" class="bottoms">
    <div class="btn serviceBtn flex-cc">
      <div style="font-size: 20px" class="icon-kefu iconfont"></div>
    </div>
    <div class="btn phoneBtn flex-cc">
      <div style="font-size: 20px" class="icon-24gf-phoneLoudspeaker iconfont"></div>
    </div>
    <div class="btn WeChatBtn flex-cc">
      <div style="font-size: 20px" class="icon-weixin iconfont"></div>
    </div>
    <div class="btn top flex-cc" @click="goBack">
      <div style="font-size: 20px" class="icon-xiangshangjiantou iconfont"></div>
    </div>

    <div class="service">
      <img class="img" src="../../assets/底部信息栏/速邦企服客服.jpg" alt="" />
    </div>
    <div class="phone">4009-6767-99</div>
    <div class="WeChat">
      <img class="img3" src="../../assets/底部信息栏/速邦企服公众号.jpg" alt="" />
      <img class="img2" src="../../assets/底部信息栏/速邦企服小程序.jpg" alt="" />
    </div>

  </div>
</template>

<script>
export default {
  name: "Suspension",

  data() {
    return {
      scrollTop: 0,
      isShow: false, //控制返回顶部按钮的显隐
      scrollTrigger: false, //默认初始值
    };
  },

  mounted() {
    window.addEventListener("scroll", this.windowScroll);
  },

  methods: {
    // 返回顶部事件
    goBack() {
      // 防止频繁点击，故返回顶部后设置scrollTrigger为初始值
      let this_ = this;
      // 获取当前距离顶部的距离
      let scrollTop = this.scrollTop;

      let steep = scrollTop / 2000;
      let timer = setInterval(() => {
        this.scrollTrigger = true;
        // 滚动的速度逐渐变快，第一次走2000/1，然后减去已走的距离，下一次用剩下的距离再减去步进值，步进值也是不断变化，这样速度会越来越快
        scrollTop -= steep;
        // 步进值不改变的话会匀速缓慢上滑，不断增加步进值上滑的距离增加，视觉效果速度变快
        steep += 20;
        if (scrollTop <= 0) {
          clearInterval(timer);
          this.scrollTrigger = false;
        }
        document.scrollingElement.scrollTop = scrollTop;
      }, 20);
    },
    // 监听滚动条
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
      if (scrollTop > 400) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  margin: 10px;
  width: 40px;
  height: 40px;
  background: #da3833;
  border-radius: 80px;
}

.btn:hover {
  .iconfont {
    color: #000;
  }
}

.bottoms {
  position: fixed;
  bottom: 50px;
  right: 20px;
}

.img {
  width: 100px;
  height: 100px;
}

.img2 {
  position: fixed;
  bottom: 110px;
  right: 80px;
  width: 100px;
  height: 100px;
}

.img3 {
  position: fixed;
  bottom: 110px;
  right: 210px;
  width: 100px;
  height: 100px;
}

.service {
  position: fixed;
  bottom: 138px;
  right: 80px;
  display: none;
}

.phone {
  position: fixed;
  bottom: 166px;
  right: 80px;
  height: 30px;
  width: 150px;
  font-weight: bold;
  border: 1px solid #8f8f8f;
  color: #dd5410;
  border-radius: 100px;
  line-height: 30px;
  text-align: center;
  display: none;
}

.WeChat {
  bottom: 150px;
  right: 100px;
  display: none;
}



.serviceBtn:hover~.service {
  display: block;
  animation: fadeio 0.5s;
}

.phoneBtn:hover~.phone {
  display: block;
  background-color: #fff;
  animation: fadeio 0.5s;
}

.WeChatBtn:hover~.WeChat {
  display: block;
  animation: fadeio 0.5s;
}

.iconfont {
  color: #fff;
}
</style>
