import Vue from 'vue'
import VueRouter from 'vue-router'
// import CurriculumDetails from '../views/CurriculumDetails/'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/AboutUs',
    name: '关于我们',
    component: () => import('../views/AboutUs.vue'),
  },
  {
    path: '/Commodity',
    name: '产品中心',
    component: () => import('../views/Commodity.vue'),
  },
  {
    path: '/Merchandise',
    name: '产品详情',
    component: () => import('../views/Merchandise.vue'),
  },
  {
    path: '/Presscenter',
    name: '新闻中心',
    component: () => import('../views/Presscenter.vue'),
  },
  {
    path: '/Detail',
    name: '新闻详情',
    component: () => import('../views/Detail.vue'),
  },
  {
    path: '/Partner',
    name: '合作伙伴',
    component: () => import('../views/Partner.vue'),
  },
  {
    path: '/Particulars',
    name: '分类详情',
    component: () => import('../views/Particulars.vue'),
  },
  {
    path: '/Pooro',
    name: '服务方案',
    component: () => import('../views/Pooro.vue'),
  },
  {
    path: '/ContactUs',
    name: '联系我们',
    component: () => import('../views/ContactUs.vue'),
  },
]

const router = new VueRouter({
  // mode: 'hash',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // return返回期望滚动到的位置的坐标
    return { x: 0, y: 0 }
  },
})

export default router
