<template>
  <div id="app">

    <!-- <Header style="z-index: 5;position: fixed;top: 0; background-color: #fff;" v-if="$route.path != '/'"></Header> -->
    <router-view :key="$route.path"></router-view>
    <Suspension></Suspension>

  </div>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import Suspension from '@/components/Suspension/Suspension.vue'

export default {
  components: {
    Header,
    Suspension
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {

  }

}
</script>

<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  background-color: #fff;
  // overflow: hidden;
  // overflow-y: scroll;
  // 滚动条不占位置
  // overflow-y: overlay
}

/*定义整个滚动条高宽及背景：高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  // display: none;
  width: 5px !important;
  height: 5px !important;
  background-color: #F5F5F5;
}

/*定义滚动条轨道：内阴影+圆角*/
::-webkit-scrollbar-track {
  background-color: #ffffff;
}

/*定义滑块：内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #c0c0c0;
}

a {
  color: inherit;
  /* 继承父元素的字体颜色 */
  text-decoration: none;
  /* 不显示下划线 */
  background-color: transparent;
  /* 透明的背景色 */
  /* 你可以根据需要添加其他属性，并设置为默认值 */
}
</style>
